import { skipToken } from '@reduxjs/toolkit/dist/query'
import { ConnectedRouter } from 'connected-react-router'
import { createRef } from 'react'
import * as ReactDOM from 'react-dom'
import { useGetAccessRuleQuery } from '@sevenrooms/core/api'
import { Formats, legacyAdapter } from '@sevenrooms/core/timepiece'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { AppRoot, useVenueContext } from '@sevenrooms/mgr-core'
import history from '../../../application/site/static/app/manager/pages/src/shared/utils/History'
import { AccessRuleTestId } from './AccessRules.testIds'
import { AccessRulesSlideout, type AccessRulesSlideoutProps } from './AccessRulesSlideout'
import { SwitchSlideout } from './components/SwitchSlideout'
import { useAccessRulesSlideoutData } from './useAccessRulesSlideoutData'
import type { AccessRulesSlideoutRef } from './AccessRulesSlideoutForm'

let ref = createRef<AccessRulesSlideoutRef>()

export interface AccessRulesSlideoutWrapperProps extends Omit<AccessRulesSlideoutProps, 'accessRulesSlideoutData'> {
  switchSlideoutCallback: () => void
  id?: string
}

export function AccessRulesSlideoutWrapper(props: AccessRulesSlideoutWrapperProps) {
  const { venueId } = useVenueContext()

  const { data: accessRulesSlideoutData, isLoading: isAccessRulesSlideoutDataLoading } = useAccessRulesSlideoutData()
  const { data: accessRule, isLoading: isAccessRuleLoading } = useGetAccessRuleQuery(
    props.id
      ? {
          date: props.selectedDay,
          id: props.id,
          venueId,
        }
      : skipToken
  )

  const isLoading = isAccessRuleLoading || isAccessRulesSlideoutDataLoading

  return isLoading || !accessRulesSlideoutData || (!accessRule && props.id) ? (
    <Loader />
  ) : (
    <AccessRulesSlideout
      ref={ref}
      accessRule={accessRule}
      mode={props.mode}
      startDate={props.startDate}
      startTimeDisplay={props.startTimeDisplay}
      endTimeDisplay={props.endTimeDisplay}
      shiftCategories={props.shiftCategories}
      selectedDay={props.selectedDay}
      onClose={props.onClose}
      onRuleSave={r => {
        props.onRuleSave(r)
        props.onClose()
      }}
      onRuleDelete={() => {
        props.onRuleDelete()
        props.onClose()
      }}
      accessRulesSlideoutData={accessRulesSlideoutData}
    >
      <SwitchSlideout data-test={AccessRuleTestId.switchSlideout} switchSlideoutCallback={props.switchSlideoutCallback} />
    </AccessRulesSlideout>
  )
}

const render = (containerId: string, props: AccessRulesSlideoutWrapperProps, switchSlideoutCallback: () => void) => {
  if (ref.current && !ref.current.onBeforeClose()) {
    return
  }

  const node = document.getElementById(containerId)

  const onClose = () => {
    if (node) {
      ReactDOM.unmountComponentAtNode(node)
      ref = createRef<AccessRulesSlideoutRef>()
    }
    props.onClose?.()
  }

  ReactDOM.render(
    <AppRoot>
      <ConnectedRouter history={history}>
        <AccessRulesSlideoutWrapper
          id={props.id}
          mode={props.mode}
          startDate={props.startDate}
          startTimeDisplay={props.startTimeDisplay}
          endTimeDisplay={props.endTimeDisplay}
          shiftCategories={props.shiftCategories}
          selectedDay={legacyAdapter({ format: Formats.MonthDayYearBySlash, value: props.selectedDay }, Formats.iso)}
          onClose={onClose}
          onRuleSave={r => {
            props.onRuleSave(r)
            onClose()
          }}
          onRuleDelete={() => {
            props.onRuleDelete()
            onClose()
          }}
          switchSlideoutCallback={switchSlideoutCallback}
        />
      </ConnectedRouter>
    </AppRoot>,
    node
  )
}

svrExport('MgrAccessRulesSlideout', 'render', render)
